<template>
    <v-sheet
      elevation="8"
      class="mx-auto"
      :height="layout.height"
      :width="layout.width"
      ref="label_sheet"
    >
      <v-row
        no-gutters
        v-for="(row, row_index) in layout.rows"
        :key="row"
        :style="{height: layout.height / layout.rows}"
        >
        <v-col 
          v-for="(col, col_index) in layout.cols" 
          :key="col"
          :cols="12 / layout.cols"
          >
          <ShelfLabel
            v-if="products[(row_index * layout.cols) + col_index]"
            :product="products[(row_index * layout.cols) + col_index]"
            :dimensions="{
              width: (layout.width / layout.cols),
              height: (layout.height / layout.rows),
              margin: layout.margin,
              rotation: layout.rotation,
            }"

          />
        </v-col>
      </v-row>
    </v-sheet>
</template>

<script>
import ShelfLabel from '@/components/ShelfLabel'
export default {
  components: {
    ShelfLabel,
  },
  props: {
    layout: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
 .rotation-0 {
   transform: rotate(0deg);
 }

.rotation-90 {
   transform: rotate(90deg);
 }
 .rotation-180 {
   transform: rotate(180deg);
 }
 .rotation-270 {
   transform: rotate(270deg);
 }
</style>