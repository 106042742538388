<template>
  <!-- <p>{{ product }}</p> -->
  <div 
    :style="{
      width: dimensions.width,
      height: dimensions.height,
      padding: dimensions.margin,
    }"
    class="label_container"
    :class="`rotation-${dimensions.rotation}`"
    >
          <img
            v-if="dataUrl"
            :src="dataUrl"
            :style="{height: qr_size, width: qr_size}"
            />
        <div
          :style="{
            width: '100%',
            height: qr_size,
            'margin-left': (dimensions.margin / 2),
          }"
          class="text-center textsection"
          style="overflow: hidden"
        >
        <p class="text-body mb-0">
          {{ location.shelve | OneIndexFilter }} {{ location.shelf | LetterFilter }} {{ location.slot | OneIndexFilter }} {{ location.product | LetterFilter }}
        </p>
        <p class="text-body" style="font-size: 0.5em">
          {{ get_product(product) ? get_product(product).title : 'Unknown' }}
        </p>
        </div>
      
  </div>
</template>

<script>

import { OneIndexFilter, LetterFilter } from '@/utils/filters'
import { mapGetters } from 'vuex'
// import QrcodeVue from 'qrcode.vue'
import QRCode from 'qrcode'

export default {
  components: {
    // QrcodeVue,
  },
  computed: {
    ...mapGetters({
      get_product_location_by_uuid: 'warehouse/get_product_location_by_uuid',
      get_product: 'product/get_product'
    }),
    location(){
      return this.get_product_location_by_uuid(this.product)
    },
    qr_size(){
      return (this.dimensions.height - (this.dimensions.margin * 2)) < (this.dimensions.width / 2 - (this.dimensions.margin * 2)) ? (this.dimensions.height - (this.dimensions.margin * 2)) : (this.dimensions.width / 2 - (this.dimensions.margin * 2)) 
    }
  },
  filters: {
    OneIndexFilter,
    LetterFilter,
  },
  props: {
    product: {
      type: String,
      required: true,
    },
    dimensions: {
      type: Object,
      required: true,
    }
  },
  methods: {
    
  },
  data(){
    return {
      dataUrl: ''
    }
  },
  async mounted(){
    const url = await QRCode.toDataURL(this.product, {
      errorCorrectionLevel: 'M',
      width: this.qr_size,
      margin: 0,
      })
    this.dataUrl = url
  }

}
</script>
<style lang="scss" scoped>

 .label_container{
   display: flex;
   flex-direction: row;
   .textsection {
    text-align: center !important;
    font-family: "Boing", boing, sans-serif !important;
    font-style: normal;
    font-weight: normal;
    color: #000;
   }
 }

 .rotation-0 {
   transform: rotate(0deg);
 }

.rotation-90 {
   transform: rotate(90deg);
 }
 .rotation-180 {
   transform: rotate(180deg);
 }
 .rotation-270 {
   transform: rotate(270deg);
 }
</style>