<template>
    <v-card
    class="shipping_map_dialog"
    >
        <v-toolbar
          dark
          color="primary"
          fixed
        >
          <v-btn
            icon
            dark
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Print shelf labels</v-toolbar-title>
          <v-spacer></v-spacer>
          </v-toolbar>
          <template v-if="!loading">
            
            <v-card
              outlined
              class="ma-4 pa-2"
              round
              >
              <v-card-title>
                Layout
                <v-spacer/>
                    <!-- <v-btn
                      fab
                      color="primary"
                      small
                      class="mx-4"
                      @click="rotate"
                      >
                      <v-icon>
                        mdi-rotate-right
                      </v-icon>
                    </v-btn> -->
                <v-btn
                  color="primary"
                  :disabled="!touched_form"
                  @click="save_default"
                  >Save as default</v-btn>
              </v-card-title>
              <v-card-text>
              <!-- <v-text-field 
                v-model="mm_height"
                label="height"
                />
                <v-text-field 
                v-model="mm_width"
                label="width"
                /> -->
                <v-row>
                  <v-col cols="12" md="6">
                    <v-subheader>
                      Paper width:
                    </v-subheader>
                      <v-slider
                        v-model="mm_width"
                        class="mx-4"
                        :max="300"
                        :min="20"
                        persistent-hint
                        :hint="`${mm_width}mm / ${to_inch(mm_width)}in`"
                        @change="touched_form = true"
                      ></v-slider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader>
                      Paper height:
                    </v-subheader>
                      <v-slider
                        v-model="mm_height"
                        class="mx-4"
                        :max="300"
                        :min="20"
                        persistent-hint
                        :hint="`${mm_height}mm / ${to_inch(mm_height)}in`"
                        @change="touched_form = true"
                      >
                      </v-slider>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader>
                      Label margin:
                    </v-subheader>
                      <v-slider
                        v-model="mm_margin"
                        class="mx-4"
                        :max="10"
                        :min="0"
                        persistent-hint
                        :hint="`${mm_margin}mm / ${to_inch(mm_margin)}in`"
                        @change="touched_form = true"
                      >
                      </v-slider>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader>
                      Label columns:
                    </v-subheader>
                      <v-slider
                        v-model="cols"
                        class="mx-4"
                        :max="4"
                        :min="1"
                        persistent-hint
                        :hint="`${cols} columns`"
                        @change="touched_form = true"
                      >
                      </v-slider>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader>
                      Label rows:
                    </v-subheader>
                      <v-slider
                        v-model="rows"
                        class="mx-4"
                        :max="20"
                        :min="1"
                        persistent-hint
                        :hint="`${rows} rows`"
                        @change="touched_form = true"
                      >
                      </v-slider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-divider />
            <v-card
              outlined
              class="ma-4 pa-2"
              round
              >
              <v-card-text>
                  <v-autocomplete
                    v-model="selected_label_ids"
                    multiple
                    item-value="uuid"
                    item-text="title"
                    :items="shelfed_products"
                    label="Labels"
                    >
                    <template v-slot:selection="{ index }">
                    <v-chip v-if="index === 0">
                      {{ selected_label_ids.length }} selected
                    </v-chip>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn
                      small
                      outlined
                      @click="selected_label_ids.length ? selected_label_ids = [] : selected_label_ids = shelfed_products.map(p => p.uuid)"
                      >
                      {{ selected_label_ids.length ? 'Clear all' : 'Select all'}}
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-card-text>
            </v-card>
            <v-divider />
              <v-alert
                v-if="show_reminder"
                type="warning"
                outlined
                class="mx-8"
                >
              Remember to mark all printed after printing, to enable QR reading on printed labels!
              </v-alert>
                <v-sheet
                class="pa-12 ma-8 label_wrap"
                color="grey lighten-3"
                v-if="selected_label_ids.length"
                >
                <div class="btn_wrapper">
                  <div class="btn_section">
                    <div class="d-flex flex-column">
                    <v-btn
                      @click="send_to_printserver"
                      small
                      color="primary"
                      class="my-2"
                      v-if="company.printserver_id"
                      :loading="loading_printserver"
                      >
                      Send to printserver
                      </v-btn>
                    <v-btn
                      color="primary"
                      @click="mark_printed"
                      small
                      :loading="loading_printmark"
                      >
                      Mark printed
                      <!-- <v-icon>
                        mdi-check
                      </v-icon> -->
                    </v-btn>
                    </div>
                    <v-btn
                      fab
                      color="primary"
                      class="ml-8"
                      v-print="'#shelf_label_paper'"
                      @click="show_reminder = true"
                      >
                      <v-icon>
                        mdi-printer-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                  <div id="shelf_label_paper">
                    <ShelfLabelPaper
                      :products="s"
                      :layout="{ ...default_shelflabel_sheet, height, width, margin, rotation }"
                      v-for="(s, i) in sheet_products"
                      :key="`sheet-${i}`"
                      class="ma-12 label_paper"
                      style="zoom: 1"
                      ref="shelf_label_paper"
                      />
                  </div>
                </v-sheet>
              <v-alert
                v-else
                type="success"
                outlined
                class="ma-8"
                >No labels selected</v-alert>
            
            </template>
            <template v-else>
              <v-card-text class="text-center mt-12">
                  <v-progress-circular
                    indeterminate
                    size="50"
                    color="primary"
                  ></v-progress-circular>
              </v-card-text>
            </template>
      </v-card>
</template>

<script>
import ShelfLabelPaper from '@/components/ShelfLabelPaper'
// import print from 'print-js'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapGetters } from 'vuex'
import { jsPDF } from "jspdf";
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'

export default {
  components: {
    ShelfLabelPaper,
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      default_shelflabel_sheet: state => state.warehouse.company.default_shelflabel_sheet,
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      shelfed_products: 'warehouse/shelfed_products',
    }),
    ...mapFields([
      'warehouse.company.default_shelflabel_sheet.rows',
      'warehouse.company.default_shelflabel_sheet.cols',
      'warehouse.company.default_shelflabel_sheet.mm_height',
      'warehouse.company.default_shelflabel_sheet.mm_width',
      'warehouse.company.default_shelflabel_sheet.mm_margin',
      // 'warehouse.company.default_shelflabel_sheet.rotation',
    ]),
    sheet_products(){
      const per_sheet = this.default_shelflabel_sheet.cols * this.default_shelflabel_sheet.rows
      let sheet = []
      const sheets = this.selected_label_ids.reduce((tot, p) => {
        sheet.push(p)
        if(sheet.length === per_sheet) {
          const ret = tot.concat([sheet])
          sheet = []
          return ret
          } else return tot
      },[])
      if(sheet.length) sheets.push(sheet)
      return sheets
    },
    height(){
      return Number(this.default_shelflabel_sheet.mm_height / 0.2645833333)
    },
    width(){
      return Number(this.default_shelflabel_sheet.mm_width / 0.2645833333)
    },
    margin(){
      return Number(this.default_shelflabel_sheet.mm_margin / 0.2645833333)
    }
  },
  data() {
    return {
      loading: true,
      loading_printmark: false,
      touched_form: false,
      selected_label_ids: [],
      rotation: 0,
      show_reminder: false,
      loading_printserver: false,
    }
  },
  methods: {
    async send_to_printserver(){
      this.loading_printserver = true
      const doc = new jsPDF({ 
        orientation: Number(this.default_shelflabel_sheet.mm_width) > Number(this.default_shelflabel_sheet.mm_height) ? 'landscape' : 'portrait',
        format: [Number(this.default_shelflabel_sheet.mm_width), Number(this.default_shelflabel_sheet.mm_height)],
        putOnlyUsedFonts: true,
        compress: true,
        unit: 'mm'
        })
      for(let i = 0; i < this.$refs.shelf_label_paper.length; ++i){
        await new Promise((res) => {
          doc.html(this.$refs.shelf_label_paper[i].$refs.label_sheet.$el.innerHTML, {
          width: Number(this.default_shelflabel_sheet.mm_width),
          windowWidth: this.width, // + 20,
          callback: d => res(d),
          x: 0,
          y: this.default_shelflabel_sheet.mm_height * i,
          autoPaging: true,
        })
      })
      }
      while(doc.getNumberOfPages() > this.$refs.shelf_label_paper.length) {
        doc.deletePage(doc.getNumberOfPages())
      }
      // doc.save('test.pdf')
      const buffer = doc.output('arraybuffer')
      console.log(buffer)
      const { uid } = require('uid')
      const job_id = uid(16)
      await firebase.storage().ref().child(`printjobs/${this.company.printserver_id}/${ job_id }.pdf`).put(buffer, {contentType: 'application/pdf' })
      const ps_ref = firebase.firestore().collection('printservers').doc(this.company.printserver_id)
      const printserver = await ps_ref.get().then(res => res.data())
      await ps_ref.set({
      jobs: [{
        created_at: new Date().toISOString(),
        label_id: job_id,
        job_id,
        description: 'Shelf labels',
        media: 'shelf_labels', 
        // carrier_id,
        // format: format || null,
      }].concat(printserver.jobs)
      }, {
      merge: true
      })
      this.loading_printserver = false
    },
    rotate(){
      this.rotation = this.rotation === 270 ? 0 : (this.rotation + 90) 
      console.log(this.rotation);
    },
    save_default(){
      this.touched_form = false
      this.$store.dispatch('warehouse/save_company', ['default_shelflabel_sheet'])
    },
    to_inch(mm){
      return Math.round(mm * 0.0393701 * 100) / 100
    },
    async mark_printed(){
      this.loading_printmark = true
      this.$store.commit('warehouse/SET_MULTIPLE_SLOTS', this.products.map(item => ({ ...item, printed: true })) )
      if(!this.$store.state.warehouse.shelf_changed) await this.$store.dispatch('warehouse/save_shelf')
      this.selected_label_ids = []
      this.loading_printmark = false
    }
  },
  async mounted(){
    this.loading = false
    this.selected_label_ids = this.products.map(p => p.product_uid)
  },

}
</script>
<style lang="scss" scoped>
@media print {
    .label_paper { 
      page-break-before: always;
    } 
}

.btn_wrapper {
  position: sticky;
  top: 20px;
  width: 100%;
  .btn_section {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  //   .print_btn {
  //   position: absolute;
  //   right: 0px
  // }
  }

}
// .label_wrap {
//   overflow-y: scroll;
//   max-height: 
// }
 .rotation-0 {
   transform: rotate(0deg);
 }

.rotation-90 {
   transform: rotate(90deg);
 }
 .rotation-180 {
   transform: rotate(180deg);
 }
 .rotation-270 {
   transform: rotate(270deg);
 }
</style>
