var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label_container",class:("rotation-" + (_vm.dimensions.rotation)),style:({
    width: _vm.dimensions.width,
    height: _vm.dimensions.height,
    padding: _vm.dimensions.margin,
  })},[(_vm.dataUrl)?_c('img',{style:({height: _vm.qr_size, width: _vm.qr_size}),attrs:{"src":_vm.dataUrl}}):_vm._e(),_c('div',{staticClass:"text-center textsection",staticStyle:{"overflow":"hidden"},style:({
          width: '100%',
          height: _vm.qr_size,
          'margin-left': (_vm.dimensions.margin / 2),
        })},[_c('p',{staticClass:"text-body mb-0"},[_vm._v(" "+_vm._s(_vm._f("OneIndexFilter")(_vm.location.shelve))+" "+_vm._s(_vm._f("LetterFilter")(_vm.location.shelf))+" "+_vm._s(_vm._f("OneIndexFilter")(_vm.location.slot))+" "+_vm._s(_vm._f("LetterFilter")(_vm.location.product))+" ")]),_c('p',{staticClass:"text-body",staticStyle:{"font-size":"0.5em"}},[_vm._v(" "+_vm._s(_vm.get_product(_vm.product) ? _vm.get_product(_vm.product).title : 'Unknown')+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }