<template>
  <div class="px-2 px-md-8">
    <v-row>
      <v-col class="grow">
        <v-autocomplete
          :items="products"
          item-text="title"
          item-value="uuid"
          prepend-icon="mdi-magnify"
          placeholder="Search product"
          clearable
          @change="show_product_location"
          ></v-autocomplete>
      </v-col>
      <v-col class="shrink d-flex justify-space-between">
          <v-dialog
            v-model="dialog"
            fullscreen
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                >
                {{ get_unprinted_shelflabels.length ? `${get_unprinted_shelflabels.length} unprinted shelf labels` : 'Shelf labels' }}
              </v-btn>
            </template>
            <ShelfLabelsDialog 
              :products="get_unprinted_shelflabels"
              @close="dialog = false"
              />
          </v-dialog>
          <!-- <v-dialog
            v-model="quick_setup_dialog"
            fullscreen
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="ml-6"
                >
                {{ 'Quick setup' }}
              </v-btn>
            </template>
            <WarehouseQuickSetupDialog 
              @close="quick_setup_dialog = false"
              />
          </v-dialog> -->
      </v-col>
    </v-row>
      <v-row
        v-drag-and-drop:options="options"
        class="warehouse-wrapper"
        @reordered="reordered"
        >
        <v-col 
          v-for="(shelve, index) in warehouse.shelves" 
          :key="`shelve-${index}`" 
          class="shelve-wrapper"
          :data-id="index"
          cols="3"
          md="2"
          xl="1"
          >
          <div class="shelve text-center" :class="highlighted === index.toString() ? 'highlight' : ''" @click="$router.push(`warehouse/shelve/${index + 1}`)">
            <div class="shelf_number">
              <div class="shelf_text">
                {{ shelve.name || index + 1 }}
              </div>
            </div>
            <div v-for="(shelf, i) in warehouse.shelves[index].shelfs" 
              :key="`shelf-${i}`" 
              :style="{height: `${100 / warehouse.shelves[index].shelfs.length}%`}"
              class="shelf"
              >
                <!-- <div v-for="(slot, slot_i) in warehouse.shelves[index][i]" 
                :key="`slot-${slot_i}`"
                :style="{width: `${100 / warehouse.shelves[index][i].length}%`}"
                class="slot"
                >
                  {{ `${index}${i}${slot_i}`}}
                </div> -->
              <!-- SHELF  -->
            </div>
          </div>
            <div class="buttons_wrapper">
              <v-btn fab x-small color="primary" class="badge-plus" @click="addShelve(index)">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn fab x-small color="secondary" :disabled="warehouse.shelves.length === 1" class="badge-minus" @click="removeShelve(index)">
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
              <v-btn fab x-small color="grey" :disabled="warehouse.shelves.length === 1" class="badge-drag" >
                <v-icon dark>
                  mdi-drag
                </v-icon>
              </v-btn>
            </div>

        </v-col>
      </v-row>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { uid } from 'uid'
import { VueDraggableDirective } from 'vue-draggable'

import ShelfLabelsDialog from '@/components/ShelfLabelsDialog'
// import WarehouseQuickSetupDialog from '@/components/WarehouseQuickSetupDialog'
// import { CurrencyFilter } from '@/utils/filters'


export default {
  directives: {
    dragAndDrop: VueDraggableDirective,
  },
  computed: {
    ...mapState({
      warehouse: state => state.warehouse,
      products: state => state.product.products,
    }),
    ...mapGetters({
      get_product_location_by_uuid: 'warehouse/get_product_location_by_uuid',
      get_plan: 'warehouse/get_plan',
      get_unprinted_shelflabels: 'warehouse/get_unprinted_shelflabels'
    })
  },
    components: {
      ShelfLabelsDialog,
      // WarehouseQuickSetupDialog,
    },
  data() {
    return {
      loading: true,
      search: '',
      highlighted: '',
      dialog: false,
      quick_setup_dialog: false,
      options: { 
          draggableSelector: '.shelve-wrapper',
          dropzoneSelector: '.warehouse-wrapper',
          // onDragstart: () => console.log('dragging'),
          // onDrop: event => console.log('drop', event)
          }
    }
  },
  methods: {
    reordered( { detail } ){
      const id = +detail.ids[0]
      const { index } = detail
      if(id === index) return
      console.log('reordered', id, index)
      this.warehouse.shelves = this.warehouse.shelves.map((shelve, index) => ({
        ...shelve,
        name: shelve.name || (index + 1).toString(),
      }))
      const shelf = this.warehouse.shelves.splice(id, 1)
      this.warehouse.shelves.splice(index, 0, shelf[0])
      
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
    },
    addShelve(index){
      if(this.warehouse.shelves.length >= this.get_plan.max_shelves) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more shelves to be added. Upgrade to add more!`)
        }
      this.warehouse.shelves.splice(index + 1, 0,  {
        id: uid(), 
        name: this.warehouse.shelves.some(s => s.name) ? 
          `Nameless ${this.warehouse.shelves.filter(s => s.name?.includes('Nameless')).length}`
          : null
          ,
        shelfs: [{ id: uid(), slots: [ {id: uid(), products: [{uid: "", id: uid() }] }]}]
      }) 
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
      console.log(index + 1)
      this.$store.commit('warehouse/SET_UNPRINTED_PAST', { shelve: {from: index + 1} })
    },
    removeShelve(index){
      this.warehouse.shelves.splice(index, 1)
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
      this.$store.commit('warehouse/SET_UNPRINTED_PAST', { shelve: {from: index} })
    },
    show_product_location(uuid){
      const position = this.get_product_location_by_uuid(uuid)
      if(!position) return
      this.highlighted = position.shelve.toString()
      setTimeout(() => {
        this.highlighted = ''
        this.$router.push(`warehouse/shelve/${Number(position.shelve) + 1}?shelf=${position.shelf}&slot=${position.slot}&product=${position.product}`)
        }, 500)
    }
  },
  mounted(){
    if(this.$route.query.showQuickSetup) this.quick_setup_dialog = true
    else this.quick_setup_dialog = false
  },

  
}
</script>
<style lang="scss" scoped>
  .shelve {
    border-left: 1px solid black;
    border-right: 1px solid black;
    // border-radius: 5px;
    min-height: 350px;
    width: 100%;
    max-width: 180px;
    display: inline-block;
    &.highlight {
      animation-duration: .5s;
      animation-name: highlight;
      // background-color: rgba(191, 191, 63, 0.32)
    }
  }

  @keyframes highlight {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: rgba(191, 191, 63, 0.32)
    }
    100% {
      background-color: inherit;
    }
  }
  .slot {
    position: inline-block;
    outline: 1px solid black;
    height: 100%;
  }

  .shelf_number {
    position: absolute;
    padding: 0px 10px
    // width: 100px;
    // background-color: red;
    // left: 100px;
  }

  .shelf_text {
    // position: relative;
    // left: 100%;
    // width: 100%;
  }

  .buttons_wrapper {
    display: inline;
    width: 0px;
    height: 0px;
    position: relative;
    // position: absolute;
  }
  .badge-plus {
  // display: inline-block;
  position: absolute;
  left: -15px;
  top: -260px;
  }
  .badge-minus {
  // display: inline-block;
  position: absolute;
  left: -15px;
  top: -220px;
  }

  .badge-drag {
    position: absolute;
    left: -15px;
    top: -180px;
  }

  .shelf {
    cursor: pointer;
    border-bottom: 1px solid black;
    position: relative;
    // white-space: nowrap;
  }
</style>